<template>
  <div>
    <b-container class="mt-4">
      <!-- Search Input Field and Search Button -->
      <b-row class="m-auto">
        <b-col md="12">
          <b-form-group class="w-50 m-auto">
            <b-input-group>
              <b-form-input
                v-model="localSearchQuery"
                @keydown.enter="onSearch"
                placeholder="Search for Doctors, Departments, Hospitals..."
                size="lg"
              />
              <b-input-group-append>
                <b-button @click="onSearch" variant="primary">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- No results found message -->
      <div v-if="searchResults.length === 0" class="text-center pt-3">
        <h2 class="font-weight-bolder text-colorBlue text-center">
          No results found for "{{ searchQuery }}"
        </h2>
        <p>Try using another relevant keyword</p>
      </div>

      <!-- Display search results -->
      <div v-else class="text-center pt-3">
        <h2 class="font-weight-bolder text-colorBlue text-center mb-3">
          Search Results For Your Keyword "{{ searchQuery }}"
        </h2>

        <div v-for="(result, index) in searchResults" :key="index" class="mb-3">
          <div class="d-flex">
            <h3 class="mr-2 font-weight-bolder text-colorGreen">
              {{ index + 1 }}.
            </h3>
            <h3 class="cursor-pointer font-weight-bolder text-colorGreen">
              {{ result.name }}
            </h3>
            <p class="cursor-pointer text-colorText ml-50">
              ({{ result.obj }})
            </p>
          </div>
          <div class="text-left">
            <h5 class="cursor-pointer font-wight-bold text-colorText">
              {{ result.description }}....
              <span
                @click.stop="goToResultPage(result)"
                class="cursor-pointer text-colorBlue font-weight-bolder"
                style="font-size: 1rem"
              >
                See More
              </span>
            </h5>
          </div>
        </div>
      </div>
      <p v-if="canLoadMore" class="ml-3">click show more for further results</p>
      <div class="d-flex mb-2 ml-3">
        <b-badge
          v-if="canLoadMore"
          @click="loadMoreResults"
          variant="success"
          class="font-weight-bolder cursor-pointer text-white"
        >
          Show More
        </b-badge>
        <b-badge
          v-show="currentPage > 1"
          @click="resetResults"
          variant="danger"
          class="ml-3 font-weight-bolder cursor-pointer text-white"
        >
          Show Less
        </b-badge>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      localSearchQuery: this.$route.query.search || "", // Local search query
      searchResults: [], // Store the search results from the API
      currentPage: 1, // Track the current page number
      canLoadMore: false, // Indicate if more results can be loaded
    };
  },
  methods: {
    ...mapActions({
      getSearchView: "appData/getSearchView", // Action to fetch search results
    }),

    // Handle search when button is clicked or Enter is pressed
    async onSearch() {
      if (this.localSearchQuery) {
        this.updateSearchQuery(this.localSearchQuery);
        this.currentPage = 1; // Reset page to the first page
        await this.fetchSearchResults(true); // Reset the results
      } else {
        this.searchResults = []; // Clear results if the search query is empty
        this.canLoadMore = false;
      }
    },

    // Fetch search results based on the current search query
    async fetchSearchResults(reset = false) {
      const searchQuery = this.localSearchQuery;

      if (searchQuery) {
        try {
          const res = await this.getSearchView({
            search: searchQuery,
            pageNumber: this.currentPage,
          });
          const results = res.data.results || [];

          if (reset) {
            // Reset results and pagination for a new search
            this.searchResults = results;
            this.currentPage = 1;
          } else {
            // Append results for subsequent pages
            this.searchResults = [...this.searchResults, ...results];
          }

          // Determine if there are more results to load based on the 'next' field
          this.canLoadMore = !!res.data.next; // `next` is not null if more data is available
        } catch (error) {
          console.error("Error fetching search results:", error);
          this.searchResults = []; // Clear results on error
          this.canLoadMore = false;
        }
      } else {
        // Clear results if the search query is empty
        this.searchResults = [];
        this.canLoadMore = false;
      }
    },
    async loadMoreResults() {
      this.currentPage += 1; // Increment page number
      await this.fetchSearchResults();
    },

    resetResults() {
      if (this.currentPage > 1) {
        const resultsPerPage = 5; // Number of results per page (from API response)
        this.currentPage -= 1; // Decrease the page number
        this.searchResults = this.searchResults.slice(
          0,
          this.currentPage * resultsPerPage
        ); // Keep results for current pages only
        this.canLoadMore = true; // Ensure "Show More" is visible again
      }
    },

    // Navigate to the specific result's page based on its type
    goToResultPage(result) {
      if (result.obj === "Doctor") {
        this.$router.push({
          name: "Doctors",
          query: { id: result.id }, // Pass doctor ID in the query
        });
      } else if (result.obj === "Department") {
        this.$router.push({
          name: "Treatment",
          params: { treatmentName: result.name, id: result.id }, // Pass department ID in params
        });
      } else if (result.obj === "Hospital") {
        this.$router.push({
          name: "Hospital",
          params: { hospitalName: result.name, id: result.id }, // Pass hospital ID in params
        });
      }
    },

    // Update the URL search query
    updateSearchQuery(query) {
      if (query) {
        this.$router.push({
          name: "SearchView", // Ensure you are navigating to the same route
          query: { search: query }, // Update the search query in the URL
        });
      } else {
        this.$router.push({ name: "SearchView" }); // Navigate without query if search is cleared
      }
    },
  },
  computed: {
    // Computed property to capture the search query from the URL
    searchQuery() {
      return this.$route.query.search || this.localSearchQuery; // Fallback to localSearchQuery if URL search is not set
    },
    paginatedResults() {
      return this.searchResults;
    },
  },
  watch: {
    "$route.query.search": "fetchSearchResults", // Still needed for URL updates
  },
  mounted() {
    this.fetchSearchResults(); // Fetch search results when the component is mounted
  },
};
</script>

<style scoped>
/* Add any custom styles you need */
</style>
